<template>
  <div
    class="ListButton"
    :class="{'IsActive': isActive}"
    @click="onButtonClick" >
    <span class="Text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'ListButton',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onButtonClick() {
      this.$emit('button-click', this.id)
    },
  },
}
</script>

<style lang="stylus" scoped>
  .ListButton
    display flex
    align-items center
    justify-content center
    min-width 70px
    min-height 40px
    padding 5px 10px
    margin 0px 5px 10px
    background-color $color_grey_lightest
    border 1px solid lighten($color_grey_lighter, 25%)
    box-shadow $box_shadow_1
    cursor pointer
    span
      display block
      text-align center
      text-transform uppercase
      font-size 0.875rem
    &.IsActive
      background-color $color_primary
      border-color $color_primary
      box-shadow $box_shadow_1
      span
        color #fff
</style>
