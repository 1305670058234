<template>
  <div
    class="ButtonList"
    :class="rootClasses">
    <ListButton
      v-for="button in buttons"
      :key="button.id"
      :id="button.id"
      :text="button.text"
      :isActive="button.isActive"
      @button-click="onButtonClick" />
  </div>
</template>

<script>
import { ListButton } from '@/components/FormElements'

export default {
  name: 'ButtonList',
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
    layout: {
      type: String,
      default: 'wrap', // 'wrap' | 'even'
    },
  },
  computed: {
    rootClasses() {
      return {
        LayoutWrap: this.layout === 'wrap',
        LayoutEven: this.layout === 'even',
      }
    },
  },
  methods: {
    onButtonClick(buttonID) {
      this.$emit('button-click', { groupName: this.groupName, buttonID })
    },
  },
  components: {
    ListButton,
  },
}
</script>

<style lang="stylus" scoped>
  .ButtonList
    display flex
    margin 0px -5px
    &.LayoutEven
      flex-wrap wrap
    &.LayoutEven
      > div
        flex-grow 2
</style>
