<template>
  <div class="IconButton">
    <div class="ButtonIcon">
      <component :is="iconToUse" />
    </div>
    <div class="ButtonText">
      <span class="Title">{{ text }}</span>
      <span
        class="Required"
        v-if="isRequired">{{ mixWB('REQUIRED') }}</span>
    </div>
  </div>
</template>

<script>
import TextIcon from '@/assets/svg/text.svg?inline'
import HouseIcon from '@/assets/svg/house.svg?inline'
import UserIcon from '@/assets/svg/user.svg?inline'
import CheckIcon from '@/assets/svg/check.svg?inline'
import QuestionIcon from '@/assets/svg/question.svg?inline'

export default {
  name: 'IconButton',
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    iconToUse() {
      if (this.icon === 'text') {
        return TextIcon
      }
      if (this.icon === 'house') {
        return HouseIcon
      }
      if (this.icon === 'user') {
        return UserIcon
      }
      if (this.icon === 'check') {
        return CheckIcon
      }
      if (this.icon === 'question') {
        return QuestionIcon
      }
      return null
    },
  },
  components: {
    TextIcon,
    HouseIcon,
    UserIcon,
    CheckIcon,
    QuestionIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .IconButton
    display flex
    width 100%
    height 40px
    border 1px solid $color_primary
    background-color #fff

  .ButtonIcon
    box(38px)
    background-color $color_primary
    padding 8px
    svg
      fill #fff

  .ButtonText
    display flex
    flex-direction column
    justify-content center
    padding-left 10px
    span
      line-height 1
    .Title
      text-transform uppercase
      font-weight bold
      line-height 1.25
    .Required
      font-size 0.75rem
      color $color_grey
</style>
